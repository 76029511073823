import $ from 'jquery';
import lightGallery from 'lightgallery';
import '@fancyapps/fancybox';

// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'
import lgAutoplay from 'lightgallery/plugins/autoplay'
import 'jquery-modal';

import './base';
import './yandexGoals';

import '../styles/index.scss';
import '../img/favicon.png';

// if (process.env.NODE_ENV === 'development') {
//   require('../index.ejs');
// }

const VideoBlocks = document.querySelectorAll('.lightgallery-video');
const ImageBlocks = document.querySelectorAll('.lightgallery-image');
const FeedbackBlocks = document.querySelectorAll('.feedbacks');

ImageBlocks.forEach(ImageBlock => {
  lightGallery(ImageBlock, {
    plugins: [lgZoom, lgThumbnail],
    speed: 500,
  });
})

$(function() {
  $('button[data-modal]').on('click', function() {
  $($(this).data('modal')).modal({
      fadeDuration: 500,
      fadeDelay: 0.30
  });
  return false;
  });
});