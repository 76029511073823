const metrikaID = 82664416;
const reachPhoneGoal = () => ym(metrikaID, 'reachGoal', 'phone');
const reachMessangerGoal = () => ym(metrikaID, 'reachGoal', 'messenger');
const reachSocialGoal = () => ym(metrikaID, 'reachGoal', 'social');

const allPhones = document.querySelectorAll('a[href*="tel:"]');
const allWhatsApps = document.querySelectorAll('a[href*="api.whatsapp.com"]');
const allInstagramLinks = document.querySelectorAll('a[href*="www.instagram.com"]');
const allVkLinks = document.querySelectorAll('a[href*="vk.com"]');

allPhones.forEach((phone)=>phone.addEventListener('click', reachPhoneGoal));
allWhatsApps.forEach((whatsApp)=>whatsApp.addEventListener('click', reachMessangerGoal));
allInstagramLinks.forEach((instagramLink)=>instagramLink.addEventListener('click', reachSocialGoal));
allVkLinks.forEach((vkLink)=>vkLink.addEventListener('submit', reachSocialGoal));